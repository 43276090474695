import React, { useEffect, useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import styles from "../../styles/styles";
import { Link, useNavigate } from "react-router-dom";
import { RxAvatar } from "react-icons/rx";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import { getAllSaccoUsers } from "../../redux/actions/user";
import { useDispatch } from "react-redux";
import Loader from "../Layout/Loader";

const Signup = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // State to control the loader
  // const [avatar, setAvatar] = useState(null);

  useEffect(() => {
    dispatch(getAllSaccoUsers());
  }, [dispatch]);

  // const navigate = useNavigate()

  // fule upload
  // const handleFileInputChange = (e) => {
  //   const file = e.target.files[0];
  //   setAvatar(file);
  // };

  useEffect(() => {
    // Simulate a network request or some async operation
    setTimeout(() => {
      setIsLoading(false); // Set loading to false after a delay
    }, 500); // Adjust the timeout duration as needed
  }, []);

  if (isLoading) {
    return <Loader />; // Show loader while loading
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const config = { headers: { "Content-Type": "multipart/form-data" } };
    // meaning of uper line is that we are creating a new object with the name of config and the value of config is {headers:{'Content-Type':'multipart/form-data'}}

    const newForm = new FormData();
    // meaning of uper line is that we are creating a new form data object and we are sending it to the backend with the name of newForm and the value of newForm is new FormData()
    // newForm.append("file", avatar);
    // meanin of newForm.append("file",avatar) is that we are sending a file to the backend with the name of file and the value of the file is avatar
    newForm.append("name", name);
    newForm.append("email", email);
    newForm.append("password", password);

    axios
      .post(`${server}/user/create-user`, newForm, config)
      .then((res) => {
        toast.success(res.data.message);
        setName("");
        setEmail("");
        setPassword("");
        // setAvatar();
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  return (
    <div className="min-h-screen bg-[#EBB9F8] flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        {/* <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Link to="/">
            <img
              style={{
                height: "35px",
              }}
              src="../Lipable-logo.png"
              alt="Lipable"
            />
          </Link>
        </div> */}
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900 font-SplineSans">
          Get started
        </h2>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={handleSubmit}>
            {/* Full Name start */}
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700 font-Inter"
              >
                Full Name
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="text"
                  autoComplete="text"
                  required
                  placeholder="John Letoo"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="appearance-none font-Inter block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
                />
              </div>
            </div>
            {/* Full Name end */}

            {/* Sacco Name start */}
            {/* <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700 font-Inter"
              >
                Sacco Name{" "}
                <span className="text-gray-400">- the sacco you belong to</span>
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="text"
                  autoComplete="text"
                  required
                  placeholder="Search Sacco"
                  className="appearance-none font-Inter block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
                />
              </div>
            </div> */}
            {/* Sacco Name end */}

            {/* Email address */}
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700 font-Inter"
              >
                Email Address
              </label>
              <div className="mt-1 relative">
                <input
                  type="email"
                  name="email"
                  autoComplete="email"
                  required
                  placeholder="Enter valid email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="appearance-none font-Inter block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
                />
              </div>
            </div>
            {/* Email address end */}
            {/* Password start */}
            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700 font-Inter"
              >
                Password
              </label>
              <div className="mt-1 relative">
                <input
                  type={visible ? "text" : "password"}
                  name="password"
                  autoComplete="password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="appearance-none font-Inter block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-[#BF00FF] sm:text-sm input-field"
                />
                {visible ? (
                  <AiOutlineEye
                    className="absolute right-2 top-2 cursor-pointer"
                    size={25}
                    onClick={() => setVisible(false)}
                  />
                ) : (
                  <AiOutlineEyeInvisible
                    className="absolute right-2 top-2 cursor-pointer"
                    size={25}
                    onClick={() => setVisible(true)}
                  />
                )}
              </div>
            </div>
            {/* Password end */}

            {/* Avatar start */}

            {/* Avatar end */}

            <div>
              <button
                type="submit"
                className="group font-Inter relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#BF00FF] hover:bg-[#000]"
              >
                Sign up
              </button>
            </div>

            <div className={`font-Inter text-sm ${styles.noramlFlex} w-full`}>
              <h4>Already have an account?</h4>
              <Link
                to="/login"
                className="pl-2 text-sm font-Inter"
                style={{
                  color: "#BF00FF",
                  fontWeight: "500",
                }}
              >
                Sign In
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Signup;
