import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "../../styles/styles";
import ProductCard from "../Route/ProductCard/ProductCard";

const SuggestedProduct = ({ data }) => {
  const [productData, setProductData] = useState([]);
  const { allProducts } = useSelector((state) => state.products);

  useEffect(() => {
    if (allProducts && data?.category) {
      // Filter products by category
      const filteredProducts = allProducts.filter(
        (product) => product.category === data.category
      );

      // Shuffle and pick 4 random products
      const shuffledProducts = filteredProducts
        .sort(() => 0.5 - Math.random()) // Randomize array
        .slice(0, 4); // Limit to 4

      setProductData(shuffledProducts);
    }
  }, [allProducts, data]);

  return (
    <div className="w-full bg-white">
      {data ? (
        <div className="p-4 w-11/12 mx-auto mb-10">
          <h2 className="text-[27px] text-center md:text-start font-[600] font-SplineSans mb-1">
            Related Products
          </h2>
          <p className="font-Inter font-light mb-6">
            We have picked out a selection of products that might interest you.{" "}
          </p>
          <div className="grid grid-cols-1 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-4 xl:gap-[30px] mb-12">
            {productData.map((product) => (
              <ProductCard
                data={product}
                key={product.slug}
                slug={product.slug} // Pass slug if needed in ProductCard
              />
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SuggestedProduct;
