import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styles from "../../../styles/styles";
import {
  AiFillHeart,
  AiFillStar,
  AiOutlineEye,
  AiOutlineHeart,
  AiOutlineShoppingCart,
  AiOutlineStar,
} from "react-icons/ai";
import { backend_url } from "../../../server";
import ProductDetailsCard from "../ProductDetailsCard/ProductDetailsCard.jsx";
import { useDispatch, useSelector } from "react-redux";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../../redux/actions/wishlist";
import { addTocart } from "../../../redux/actions/cart";
import { toast } from "react-toastify";
import Ratings from "../../Products/Ratings";
import { formatCurrency } from "../../../Utils/formatCurrency.js";

const ProductCard = ({ data, isEvent }) => {
  const { wishlist } = useSelector((state) => state.wishlist);
  const { cart } = useSelector((state) => state.cart);
  const [click, setClick] = useState(false);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (wishlist && wishlist.find((i) => i._id === data._id)) {
      setClick(true);
    } else {
      setClick(false);
    }
  }, [wishlist]);

  // Remove from wish list
  const removeFromWishlistHandler = (data) => {
    setClick(!click);
    dispatch(removeFromWishlist(data));
  };

  // add to wish list
  const addToWishlistHandler = (data) => {
    setClick(!click);
    dispatch(addToWishlist(data));
  };

  // Add to cart
  const addToCartHandler = (id) => {
    const isItemExists = cart && cart.find((i) => i._id === id);

    if (isItemExists) {
      toast.error("item already in cart!");
    } else {
      if (data.stock < 1) {
        toast.error("Product stock is limited!");
      } else {
        const cartData = { ...data, qty: 1 };
        dispatch(addTocart(cartData));
        toast.success("Item added to cart Successfully!");
      }
    }
  };

  return (
    <>
      <div className="w-full h-[244px] bg-[#f2f0ea] rounded-lg p-3 relative cursor-pointer">
        <div className="flex justify-end"></div>

        <Link
          to={`${
            isEvent === true
              ? `/product/${data.slug}?isEvent=true`
              : `/product/${data.slug}`
          }`}
        >
          <div className="relative zoom">
            {/* Sold Out Badge */}
            {data.stock === 0 && (
              <div className="absolute top-2 left-2 bg-red-600 text-white text-xs font-medium px-2 py-1 rounded-full z-10 font-Inter">
                Sold Out
              </div>
            )}

            {/* Discount Badge */}
            {data.stock > 0 && data.originalPrice && data.discountPrice && (
              <div className="absolute top-2 left-2 bg-[#ec00ff] text-black text-xs font-medium px-2 py-1 rounded-full z-10 font-Inter">
                {Math.round(
                  ((data.originalPrice - data.discountPrice) /
                    data.originalPrice) *
                    100
                )}
                %
              </div>
            )}

            <img
              src={`${backend_url}${data.images && data.images[0]}`}
              alt="prd"
              className="w-full h-[220px] object-cover rounded-md"
            />
          </div>
        </Link>

        <Link
          to={`${
            isEvent === true
              ? `/product/${data.slug}?isEvent=true`
              : `/product/${data.slug}`
          }`}
        ></Link>
        <Link to={`/product/${data.slug}`}>
          <div
            className="pb-3 pt-6"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "start",
            }}
          >
            <div>
              <h3 className="font-[500] font-Inter break-words text-[1.2rem] text-[#000]">
                {data.name.length > 35
                  ? data.name.slice(0, 35) + "..."
                  : data.name}
              </h3>
              <h5 className="font-[400] font-Inter break-words text-[0.9rem] text-[#777]">
                {data.category.length > 40
                  ? data.category.slice(0, 40) + "..."
                  : data.category}
              </h5>
            </div>

            {/* Star Rating */}
            {/* <div className="flex mt-1">
              <Ratings rating={data?.ratings} />
            </div> */}
          </div>

          {/* <div className="py-2 block items-center justify-between">
            <div className="block">
              <h4 className="font-[500] text-[1rem] text-[#f73b3b] font-Inter">
                {formatCurrency(
                  data.originalPrice === 0
                    ? data.originalPrice
                    : data.discountPrice
                )}
              </h4>

              <h4 className="font-[500] text-[1rem] text-[#333] line-through">
                {formatCurrency(data.originalPrice ? data.originalPrice : null)}
              </h4>
            </div>
          </div> */}
          <div className="py-2 block items-center justify-between">
            <div className="block">
              {data.originalPrice ? (
                <>
                  {/* Show discountPrice in red if originalPrice is available */}
                  <h4 className="font-[500] text-[1rem] text-[#f73b3b] font-Inter">
                    {formatCurrency(data.discountPrice)}
                  </h4>
                  {/* Show originalPrice in grey with a line-through */}
                  <h4 className="font-[500] text-[1rem] text-[#333] line-through">
                    {formatCurrency(data.originalPrice)}
                  </h4>
                </>
              ) : (
                <>
                  {/* Show only discountPrice in black if originalPrice is not available */}
                  <h4 className="font-[500] text-[1rem] text-[#333] font-Inter">
                    {formatCurrency(data.discountPrice)}
                  </h4>
                </>
              )}
            </div>
          </div>
        </Link>

        {/* side option */}
        {/* <div>
          {click ? (
            <AiFillHeart
              size={22}
              className="cursor-pointer absolute right-2 top-5"
              onClick={() => removeFromWishlistHandler(data)}
              color={click ? "red" : "#333"}
              title="Remove from wishlist"
            />
          ) : (
            <AiOutlineHeart
              size={22}
              className="cursor-pointer absolute right-2 top-5"
              onClick={() => addToWishlistHandler(data)}
              color={click ? "red" : "#333"}
              title="Add to wishlist"
            />
          )}
          <AiOutlineEye
            size={22}
            className="cursor-pointer absolute right-2 top-14"
            onClick={() => setOpen(!open)}
            color="#333"
            title="Quick view"
          />

          <AiOutlineShoppingCart
            size={25}
            className="cursor-pointer absolute right-2 top-24"
            onClick={() => addToCartHandler(data._id)}
            color="#444"
            title="Add to cart"
          />
          {open ? <ProductDetailsCard setOpen={setOpen} data={data} /> : null}
        </div> */}
      </div>
    </>
  );
};

export default ProductCard;
