import React from "react";
import styles from "../../styles/styles";
import { backend_url } from "../../server";

const Sponsored = () => {
  return (
    <div
      className={`${styles.section} hidden sm:block bg-white py-10 px-5 mb-8 mt-16 cursor-pointer rounded-xl`}
    >
      <div className={`${styles.heading}`}>
        <h1 className="font-SplineSans pb-5 text-3xl">Partner Stores</h1>
      </div>
      <div className="flex justify-start w-full">
        <div className="flex items-start mr-4">
          <img
            src={`${backend_url}hotpoint-logo.webp`}
            alt=""
            style={{
              width: "180px",
              height: "180px",
              objectFit: "contain",
              borderWidth: "2px",
              borderRadius: "10px",
              borderColor: "#D980F7",
            }}
          />
        </div>
        {/* <div className="flex items-start mr-4">
          <img
            src={`${backend_url}hotpoint-logo.webp`}
            alt=""
            style={{
              width: "180px",
              height: "180px",
              objectFit: "contain",
              borderWidth: "2px",
              borderRadius: "10px",
              borderColor: "#D980F7",
            }}
          />
        </div> */}
      </div>
    </div>
  );
};

export default Sponsored;
