import { BsLaptop } from "react-icons/bs";
import { IoPhonePortraitOutline } from "react-icons/io5";
import { IoTvOutline } from "react-icons/io5";
import { IoBicycleOutline } from "react-icons/io5";
import { BsFillLuggageFill } from "react-icons/bs";
import { IoGameControllerOutline } from "react-icons/io5";
import { IoConstructOutline } from "react-icons/io5";
import { IoCameraOutline } from "react-icons/io5";
import { IoCarSportOutline } from "react-icons/io5";
import { IoCalculatorOutline } from "react-icons/io5";
import { BiParty } from "react-icons/bi";
import { BsCupHot } from "react-icons/bs";
import { MdOutlineLocalLaundryService } from "react-icons/md";
import { RiMickeyLine } from "react-icons/ri";
import { MdOutlineCoffeeMaker } from "react-icons/md";
import { MdOutlineSanitizer } from "react-icons/md";
import { MdOutlineHardware } from "react-icons/md";

// navigation Data
export const navItems = [
  {
    title: "How It Works",
    url: "https://lipable.com/how-it-works/",
  },
  {
    title: "Shop",
    url: "/products",
  },
];

// branding data
export const brandingData = [
  {
    id: 1,
    title: "Free Shipping",
    Description: "From all orders over 100$",
    icon: (
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 1H5.63636V24.1818H35"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="square"
        ></path>
        <path
          d="M8.72763 35.0002C10.4347 35.0002 11.8185 33.6163 11.8185 31.9093C11.8185 30.2022 10.4347 28.8184 8.72763 28.8184C7.02057 28.8184 5.63672 30.2022 5.63672 31.9093C5.63672 33.6163 7.02057 35.0002 8.72763 35.0002Z"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="square"
        ></path>
        <path
          d="M31.9073 35.0002C33.6144 35.0002 34.9982 33.6163 34.9982 31.9093C34.9982 30.2022 33.6144 28.8184 31.9073 28.8184C30.2003 28.8184 28.8164 30.2022 28.8164 31.9093C28.8164 33.6163 30.2003 35.0002 31.9073 35.0002Z"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="square"
        ></path>
        <path
          d="M34.9982 1H11.8164V18H34.9982V1Z"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="square"
        ></path>
        <path
          d="M11.8164 7.18164H34.9982"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="square"
        ></path>
      </svg>
    ),
  },
  {
    id: 2,
    title: "Daily Surprise Offers",
    Description: "Save up to 25% off",
    icon: (
      <svg
        width="32"
        height="34"
        viewBox="0 0 32 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M31 17.4502C31 25.7002 24.25 32.4502 16 32.4502C7.75 32.4502 1 25.7002 1 17.4502C1 9.2002 7.75 2.4502 16 2.4502C21.85 2.4502 26.95 5.7502 29.35 10.7002"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
        ></path>
        <path
          d="M30.7 2L29.5 10.85L20.5 9.65"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="square"
        ></path>
      </svg>
    ),
  },
  {
    id: 4,
    title: "Affortable Prices",
    Description: "Get Factory direct price",
    icon: (
      <svg
        width="32"
        height="35"
        viewBox="0 0 32 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7 13H5.5C2.95 13 1 11.05 1 8.5V1H7"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
        ></path>
        <path
          d="M25 13H26.5C29.05 13 31 11.05 31 8.5V1H25"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
        ></path>
        <path
          d="M16 28V22"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
        ></path>
        <path
          d="M16 22C11.05 22 7 17.95 7 13V1H25V13C25 17.95 20.95 22 16 22Z"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="square"
        ></path>
        <path
          d="M25 34H7C7 30.7 9.7 28 13 28H19C22.3 28 25 30.7 25 34Z"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="square"
        ></path>
      </svg>
    ),
  },
  {
    id: 5,
    title: "Secure Payments",
    Description: "100% protected payments",
    icon: (
      <svg
        width="32"
        height="38"
        viewBox="0 0 32 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.6654 18.667H9.33203V27.0003H22.6654V18.667Z"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="square"
        ></path>
        <path
          d="M12.668 18.6663V13.6663C12.668 11.833 14.168 10.333 16.0013 10.333C17.8346 10.333 19.3346 11.833 19.3346 13.6663V18.6663"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="square"
        ></path>
        <path
          d="M31 22C31 30.3333 24.3333 37 16 37C7.66667 37 1 30.3333 1 22V5.33333L16 2L31 5.33333V22Z"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="square"
        ></path>
      </svg>
    ),
  },
];

// electronic brands
export const gadgetBrandsData = [
  {
    id: 1,
    title: "LG",
    logo_url: "",
  },
  {
    id: 2,
    title: "Sony",
    logo_url: "",
  },
  {
    id: 3,
    title: "Samsung",
    logo_url: "",
  },
  {
    id: 4,
    title: "Apple",
    logo_url: "",
  },
  {
    id: 5,
    title: "Ramtons",
    logo_url: "",
  },
  {
    id: 6,
    title: "Solstar",
    logo_url: "",
  },
  {
    id: 7,
    title: "BEKO",
    logo_url: "",
  },
  {
    id: 8,
    title: "ELBA",
    logo_url: "",
  },
  {
    id: 9,
    title: "Electrolux",
    logo_url: "",
  },
  {
    id: 10,
    title: "Sonos",
    logo_url: "",
  },
];

// Apparel data
export const apparelData = [
  {
    id: 1,
    title: "Nike",
    logo_url: "",
  },
  {
    id: 2,
    title: "Adidas",
    logo_url: "",
  },
  {
    id: 3,
    title: "Levis",
    logo_url: "",
  },
  {
    id: 4,
    title: "M&M",
    logo_url: "",
  },
  {
    id: 5,
    title: "Under Amour",
    logo_url: "",
  },
];

// color data
export const colorData = [
  {
    id: 1,
    color: "Black",
    rbg: "",
  },
  {
    id: 2,
    color: "White",
    rbg: "",
  },
  {
    id: 3,
    color: "Silver",
    rbg: "",
  },
  {
    id: 4,
    color: "Gray",
    rbg: "",
  },
  {
    id: 5,
    color: "Brown",
    rbg: "",
  },
  {
    id: 6,
    color: "Blue",
    rbg: "",
  },
  {
    id: 7,
    color: "Green",
    rbg: "",
  },
  {
    id: 8,
    color: "Red",
    rbg: "",
  },
  {
    id: 9,
    color: "Yellow",
    rbg: "",
  },
  {
    id: 10,
    color: "Purple",
    rbg: "",
  },
  {
    id: 11,
    color: "Orange",
    rbg: "",
  },
  {
    id: 12,
    color: "Gold",
    rbg: "",
  },
  {
    id: 13,
    color: "Natural Titanium",
    rbg: "",
  },
];

// cloth size data
export const clothSizeData = [
  {
    id: 1,
    title: "Small",
    symbol: "S",
  },
  {
    id: 2,
    title: "Medium",
    symbol: "M",
  },
  {
    id: 3,
    title: "Large",
    symbol: "L",
  },
  {
    id: 4,
    title: "Extra Large",
    symbol: "XL",
  },
  {
    id: 5,
    title: "Extra Extra Large",
    symbol: "XXL",
  },
];

// shoe size data
export const shoeSizeData = [
  {
    id: 1,
    size: 2,
  },
  {
    id: 2,
    size: 2.5,
  },
  {
    id: 3,
    size: 3,
  },
  {
    id: 4,
    size: 3.5,
  },
  {
    id: 5,
    size: 4,
  },
  {
    id: 6,
    size: 4.5,
  },
  {
    id: 7,
    size: 5,
  },
  {
    id: 8,
    size: 5.5,
  },
  {
    id: 9,
    size: 6,
  },
  {
    id: 10,
    size: 6.5,
  },
  {
    id: 11,
    size: 7,
  },
  {
    id: 12,
    size: 7.5,
  },
  {
    id: 13,
    size: 8,
  },
  {
    id: 14,
    size: 8.5,
  },
  {
    id: 15,
    size: 9,
  },
  {
    id: 16,
    size: 9.5,
  },
  {
    id: 17,
    size: 10,
  },
  {
    id: 18,
    size: 10.5,
  },
  {
    id: 19,
    size: 11,
  },
  {
    id: 20,
    size: 11.5,
  },
  {
    id: 21,
    size: 12,
  },
  {
    id: 22,
    size: 12.5,
  },
  {
    id: 23,
    size: 13,
  },
];

// target group data
export const targetGroupData = [
  {
    id: 1,
    title: "Male",
  },
  {
    id: 2,
    title: "Female",
  },
];

// storage data
export const storageData = [
  {
    id: 1,
    size: "16 GB",
  },
  {
    id: 2,
    size: "32 GB",
  },
  {
    id: 3,
    size: "64 GB",
  },
  {
    id: 4,
    size: "128 GB",
  },
  {
    id: 5,
    size: "256 GB",
  },
  {
    id: 6,
    size: "512 GB",
  },
  {
    id: 7,
    size: "1 TB",
  },
  {
    id: 8,
    size: "2 TB",
  },
  {
    id: 9,
    size: "4 TB",
  },
];

// categories data
export const categoriesData = [
  {
    id: 1,
    title: "Sound & TV",
    name: "TV",
    image_Url: "https://lipable.s3.amazonaws.com/categories/sound-tv.webp",
    icon: <IoTvOutline size={20} />,
  },
  {
    id: 2,
    title: "Appliances",
    name: "appliances",
    image_Url: "https://lipable.s3.amazonaws.com/categories/appliances.webp",
    icon: <MdOutlineLocalLaundryService size={20} />,
  },
  {
    id: 3,
    title: "Kitchen",
    name: "kitchen",
    image_Url: "https://lipable.s3.amazonaws.com/categories/kitchen.webp",
    icon: <MdOutlineCoffeeMaker size={20} />,
  },
  {
    id: 4,
    title: "Phones",
    name: "phones",
    image_Url: "https://lipable.s3.amazonaws.com/categories/phone.webp",
    icon: <IoPhonePortraitOutline size={20} />,
  },
  {
    id: 5,
    title: "Health & Beauty",
    name: "health",
    image_Url: "https://lipable.s3.amazonaws.com/categories/health-beauty.webp",
    icon: <MdOutlineSanitizer size={20} />,
  },
  {
    id: 6,
    title: "Computers",
    name: "computers",
    image_Url: "https://lipable.s3.amazonaws.com/categories/computer.webp",
    icon: <BsLaptop size={20} />,
  },
  {
    id: 7,
    title: "Mobility",
    name: "mobility",
    image_Url: "https://lipable.s3.amazonaws.com/categories/mobility.webp",
    icon: <IoCarSportOutline size={20} />,
  },
  {
    id: 8,
    title: "Photography",
    name: "photography",
    image_Url: "https://lipable.s3.amazonaws.com/categories/photography.webp",
    icon: <IoCameraOutline size={20} />,
  },
  {
    id: 9,
    title: "Gaming",
    name: "gaming",
    image_Url: "https://lipable.s3.amazonaws.com/categories/gaming.webp",
    icon: <IoGameControllerOutline size={20} />,
  },
  {
    id: 10,
    title: "Kids",
    name: "kids",
    image_Url: "https://lipable.s3.amazonaws.com/categories/kids.webp",
    icon: <RiMickeyLine size={20} />,
  },
  {
    id: 11,
    title: "DIY",
    name: "diy",
    image_Url: "https://lipable.s3.amazonaws.com/categories/diy.webp",
    icon: <IoConstructOutline size={20} />,
  },
  {
    id: 12,
    title: "Sports",
    name: "sports",
    image_Url: "https://lipable.s3.amazonaws.com/categories/sports.webp",
    icon: <IoBicycleOutline size={20} />,
  },
  {
    id: 13,
    title: "Garden",
    name: "garden",
    image_Url: "https://lipable.s3.amazonaws.com/categories/garden.webp",
    icon: <MdOutlineHardware size={20} />,
  },
  {
    id: 14,
    title: "Office",
    name: "office",
    image_Url: "https://lipable.s3.amazonaws.com/categories/office.webp",
    icon: <IoCalculatorOutline size={20} />,
  },
  {
    id: 15,
    title: "Toys",
    name: "toys",
    image_Url: "https://lipable.s3.amazonaws.com/categories/toy.webp",
    icon: <BiParty size={20} />,
  },
];

export const footerProductLinks = [
  {
    name: "About us",
    link: "https://lipable.com/about-lipable",
  },
  {
    name: "For Saccos",
    link: "https://lipable.com/for-saccos",
  },
  {
    name: "For Merchants",
    link: "https://lipable.com/for-merchants",
  },
  {
    name: "Refer & Earn",
    link: "https://lipable.com/refer-and-earn",
  },
];

export const footercompanyLinks = [
  {
    name: "Where to Shop",
    link: "https://shop.lipable.com/stores",
  },
  {
    name: "How it Works",
    link: "https://lipable.com/how-it-works",
  },
  {
    name: "Store Directory",
    link: "https://shop.lipable.com",
  },
  {
    name: "Media",
    link: "https://lipable.com/media",
  },
];

export const footerSupportLinks = [
  {
    name: "Help",
    link: "https://help.lipable.com/",
  },
  {
    name: "Privacy Policy",
    link: "https://lipable.com/privacy-policy",
  },
  {
    name: "Terms and Conditions",
    link: "https://lipable.com/terms-conditions",
  },
  {
    name: "Return Policy",
    link: "https://lipable.com/return-policy",
  },
];
