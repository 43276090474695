import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../styles/styles";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "@material-ui/core";
import axios from "axios";
import { server, backend_url } from "../../server";
import { toast } from "react-toastify";
import { formatCurrency } from "../../Utils/formatCurrency";
import { IoInformationCircleOutline } from "react-icons/io5";
import PaystackPop from "@paystack/inline-js";

const Payment = () => {
  const [orderData, setOrderData] = useState([]);
  const [open, setOpen] = useState(false);
  const { user } = useSelector((state) => state.user);
  const { sacco } = useSelector((state) => state.sacco);
  const { cart } = useSelector((state) => state.cart);
  const [creditLimit, setCreditLimit] = useState(user && user.availableCredit);
  const [select, setSelect] = useState(1);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const orderData = JSON.parse(localStorage.getItem("latestOrder"));
    setOrderData(orderData);
  }, []);

  const order = {
    cart: orderData?.cart,
    shippingAddress: orderData?.shippingAddress,
    user: user && user,
    sacco: user && user.sacco,
    totalPrice: orderData?.totalPrice,
  };

  // Paystack handler for full payment
  const handlePaystackPayment = async (e) => {
    e.preventDefault();

    const amount =
      select === 1
        ? orderData?.totalPrice
        : orderData?.totalPrice - creditLimit;

    const credit =
      select === 1
        ? 0
        : orderData?.totalPrice - (orderData?.totalPrice - creditLimit);

    const methodType = select === 1 ? "Full Payment" : "Lipable Payment";

    const generateTransactionId = () => {
      const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      let transactionId = "";
      for (let i = 0; i < 10; i++) {
        transactionId += characters.charAt(
          Math.floor(Math.random() * characters.length)
        );
      }
      return transactionId;
    };

    const transactionId = generateTransactionId();

    const paystack = new PaystackPop();

    paystack.newTransaction({
      key: "pk_test_ee52b63f9ce5bdac00c10350b3fff2afcd8c735b",
      email: user.email,
      amount: amount * 100,
      currency: "KES",
      onSuccess: async (transaction) => {
        order.paymentInfo = {
          type: "Paystack",
          creditUsed: credit,
          transactionId: transactionId,
          reference: transaction.reference,
          paymentMethod: methodType,
        };

        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };

        await axios
          .post(`${server}/order/create-order`, order, config)
          .then((res) => {
            setOpen(false);
            navigate("/order/success");
            toast.success("Order successful!");
            localStorage.setItem("cartItems", JSON.stringify([]));
            localStorage.setItem("latestOrder", JSON.stringify([]));
            window.location.reload();
          });
      },
      onCancel: () => {
        toast.error("Payment canceled");
      },
    });
  };

  return (
    <div className="w-full flex flex-col items-center py-8">
      <div className="w-[90%] 1000px:w-[70%] block 800px:flex">
        <div className="w-full 800px:w-[65%]">
          <PaymentInfo
            user={user}
            open={open}
            setOpen={setOpen}
            handlePaystackPayment={handlePaystackPayment}
            orderData={orderData}
            select={select}
            setSelect={setSelect}
            creditLimit={creditLimit}
            setCreditLimit={setCreditLimit}
          />
        </div>
        <div
          className="w-full 800px:w-[35%] 800px:mt-0 mt-8 rounded-xl bg-white px-7"
          style={{ border: "2px solid #eee" }}
        >
          <div className="w-full py-6 border-b">
            <h2 className="font-Inter font-semibold text-[1.2rem] text-[#000] ">
              Your Order
            </h2>
          </div>
          <div className="w-full">
            {cart &&
              cart.map((i, index) => {
                return <CartSingle data={i} key={index} />;
              })}
          </div>
          <CartData orderData={orderData} />
        </div>
      </div>
    </div>
  );
};

const PaymentInfo = ({
  user,
  handlePaystackPayment,
  orderData,
  select,
  setSelect,
  creditLimit,
  setCreditLimit,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [adjustCredit, setAdjustCredit] = useState(false);

  useEffect(() => {
    if (select === 2) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  }, [select]);

  const incrementCount = () => {
    setCreditLimit((prev) =>
      Math.min(prev + 100, user && user.availableCredit)
    );
  };

  const decrementCount = () => {
    setCreditLimit((prev) => Math.max(prev - 100, 0));
  };

  const handleCreditChange = (e) => {
    const value = Math.max(
      0,
      Math.min(user && user.availableCredit, Number(e.target.value))
    );
    setCreditLimit(value);
  };

  return (
    <>
      <div
        className="w-full 800px:w-[95%] bg-[#fff] rounded-md p-5 pb-4"
        style={{ border: "2px solid #eee" }}
      >
        {/* select buttons */}
        {/* cash on delivery */}
        <div>
          <div
            className={`flex w-full pb-5 pt-5 pl-5 border-2 mb-2 rounded-lg ${
              select === 1 ? `border-[#7e7e7e]` : `border-[#e5e7eb]`
            }`}
          >
            <div
              className={`w-[25px] h-[25px] rounded-full bg-transparent border-[3px] border-[#e5e7eb] relative flex items-center justify-center `}
              onClick={() => setSelect(1)}
            >
              {select === 1 ? (
                <div className="w-[13px] h-[13px] bg-[#1d1a1acb] rounded-full" />
              ) : null}
            </div>
            <div className="flex items-center justify-between w-full">
              <h4 className="text-[18px] pl-2 font-[500] text-[#000000b1] font-Inter">
                Pay total{" "}
                <span className="text-[#000] font-[600]">
                  {formatCurrency(orderData?.totalPrice)}
                </span>
              </h4>
              <img
                style={{ height: "20px" }}
                src="../mpesa-visa.png"
                alt="Lipable"
                className="pr-4"
              />
            </div>
          </div>
        </div>

        {/* lipable payment */}
        <div>
          <div
            className={`flex w-full pb-5 pt-5 pl-5 border-2 mb-2 rounded-lg ${
              select === 2 ? `border-[#7e7e7e]` : `border-[#e5e7eb]`
            }`}
          >
            <div className="block w-full">
              <div className="flex">
                <div
                  className="w-[25px] h-[25px] rounded-full bg-transparent border-[3px] border-[#e5e7eb] relative flex items-center justify-center"
                  onClick={() => setSelect(2)}
                >
                  {select === 2 ? (
                    <div className="w-[13px] h-[13px] bg-[#1d1a1acb] rounded-full" />
                  ) : null}
                </div>
                <div className="flex items-center justify-between w-full">
                  <h4 className="text-[18px] pl-2 font-[500] text-[#000000b1] font-Inter">
                    Payment with Lipable
                  </h4>
                  <img
                    style={{ height: "20px" }}
                    src="../lipable.png"
                    alt="Lipable"
                    className="pr-4"
                  />
                </div>
              </div>
              <div
                className={`w-full expandable-section ${
                  select === 2 ? "expanded" : ""
                }`}
              >
                <div className="ml-4 mt-3 p-4 rounded-lg">
                  <div className="flex justify-between items-center">
                    <p className="text-[14px] text-[#000000] font-Inter flex">
                      Available Credit:{"  "}
                      <span className="text-[#2bd480] font-medium ml-[3px]">
                        {formatCurrency(user && user.availableCredit)}
                      </span>
                      <span className="ml-[4px] mt-[3px]">
                        <Tooltip title="Top" placement="bottom">
                          <IoInformationCircleOutline
                            size={16}
                            color="gray"
                            className="cursor-pointer"
                          />
                        </Tooltip>
                      </span>
                    </p>
                    <button
                      className="text-[#bf00ff] font-[400] font-Inter text-[14px] w-[60px] h-[25px] border border-[#bf00ff] text-center rounded-[5px]"
                      onClick={() => setAdjustCredit(!adjustCredit)}
                    >
                      Adjust
                    </button>
                  </div>

                  {adjustCredit && (
                    <div
                      className={`w-full flex items-center mt-2 p-2 rounded-md adjust-controls expandable-section ${
                        adjustCredit ? "visible" : ""
                      }`}
                      style={{ border: "2px solid #eee" }}
                    >
                      <button
                        className="text-gray-500 font-bold px-4 py-2 hover:opacity-75 transition duration-300 ease-in-out rounded-md"
                        onClick={decrementCount}
                        style={{ border: "2px solid #eee" }}
                      >
                        -
                      </button>
                      <input
                        type="number"
                        value={creditLimit}
                        onChange={handleCreditChange}
                        className="bg-white text-gray-800 font-medium px-4 py-2 font-Inter text-center w-full"
                      />
                      <button
                        className="text-gray-500 font-bold px-4 py-2 hover:opacity-75 transition duration-300 ease-in-out rounded-md"
                        onClick={incrementCount}
                        style={{ border: "2px solid #eee" }}
                      >
                        +
                      </button>
                    </div>
                  )}
                  <p className="text-[16px] text-[#000000ad] font-Inter pt-2">
                    Pay:{" "}
                    <span className="text-[#000] font-medium">
                      {formatCurrency(
                        Math.max(orderData?.totalPrice - creditLimit, 0)
                      )}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* full payment */}
      {select === 1 ? (
        <div className="w-full flex mt-6">
          <button
            onClick={handlePaystackPayment}
            className="group relative w-[300px] h-[45px] text-[16px] flex justify-center py-2 px-4 border border-transparent font-medium rounded-md text-white bg-[#000] hover:bg-[#4d4c4c] font-Inter"
          >
            Pay Now
          </button>
        </div>
      ) : null}
      {/* Lipable payment */}
      {select === 2 ? (
        <div className="w-full flex mt-6">
          <button
            onClick={handlePaystackPayment}
            className="group relative w-[300px] h-[45px] text-[16px] flex justify-center py-2 px-4 border border-transparent font-medium rounded-md text-white bg-[#000] hover:bg-[#4d4c4c] font-Inter"
          >
            Pay Now
          </button>
        </div>
      ) : null}
    </>
  );
};

const CartData = ({ orderData }) => {
  const shipping = orderData?.shipping?.toFixed(2);
  return (
    <div className="w-full bg-[#fff] rounded-md pt-5 pb-8">
      <div className="flex justify-between">
        <h3 className="text-[14px] font-[400] text-[#000000a4] font-Inter">
          Sub Total
        </h3>
        <h5 className="text-[14px] font-[600] font-Inter">
          {formatCurrency(orderData?.totalPrice - shipping)}
        </h5>
      </div>
      <br />
      <div className="flex justify-between">
        <h3 className="text-[14px] font-[400] text-[#000000a4] font-Inter">
          Delivery
        </h3>
        <h5 className="text-[14px] font-[600] font-Inter">
          {formatCurrency(shipping)}
        </h5>
      </div>
      <br />
      <div className="flex justify-between border-b pb-3">
        <h3 className="text-[14px] font-[400] text-[#000000a4] font-Inter">
          Discount:
        </h3>
        <h5 className="text-[14px] font-[600] font-Inter">
          {" "}
          {formatCurrency(
            orderData?.discountPrice ? orderData.discountPrice.toString() : null
          )}
        </h5>
      </div>
      <div className="flex justify-between p-6 bg-[#f2f0ea] rounded-lg mt-4">
        <h3 className="text-[16px] font-[400] text-[#000000a4] font-Inter">
          Total
        </h3>
        <h5 className="text-[18px] font-[600] font-Inter">
          {formatCurrency(orderData?.totalPrice)}
        </h5>
      </div>
      <br />
    </div>
  );
};

const CartSingle = ({ data }) => {
  const [value, setValue] = useState(data.qty);
  const totalPrice = data.discountPrice * value;

  return (
    <>
      <div className="border-b py-4 bg-[#fff] ">
        <div className="w-full flex items-center">
          <img
            src={`${backend_url}${data?.images[0]}`}
            className="w-[80px] h-min rounded-[6px] object-cover"
            alt="side card"
          />

          <div className="pl-[15px]">
            <h1 className="font-Inter font-bold text-[1.2rem]">{data.name}</h1>
            <h5 className="font-[400] text-[1.1rem] text-[#1d1d1d82] font-Inter">
              {" "}
              <span className="text-[#3a3a3a] font-Inter font-normal">
                {formatCurrency(data.discountPrice)}
              </span>{" "}
              x {value}
            </h5>
            {/* <h4 className="font-[400] text-[17px] pt-[3px]  text-[#d02222] font-Roboto ">
              {formatCurrency(totalPrice)}
            </h4> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Payment;
