import React from "react";
import {
  AiFillFacebook,
  AiOutlineInstagram,
  AiFillYoutube,
  AiOutlineTwitter,
} from "react-icons/ai";
import { FaXTwitter } from "react-icons/fa6";
import { IoLogoFacebook } from "react-icons/io5";
import { Link } from "react-router-dom";
import {
  footercompanyLinks,
  footerProductLinks,
  footerSupportLinks,
} from "../../static/data";

const Footer = () => {
  return (
    <>
      <div className="pt-20"></div>
      <div className="bg-[#130f0c] text-white mt-8">
        {/* <div className="md:flex md:justify-between md:items-center sm:px-12 px-4 bg-[#342ac8] py-7">
        <h1 className="lg:text-4xl text-3xl md:mb-0 mb-6 lg:leading-normal font-semibold md:w-2/5">
          <span className="text-[#56d879]">Subscribe</span> us for get news{" "}
          <br />
          events and offers
        </h1>
        <div>
          <input
            type="text"
            required
            placeholder="Enter your email..."
            className="text-gray-800
                sm:w-72 w-full sm:mr-5 mr-1 lg:mb-0 mb-4 py-2.5 rounded px-2 focus:outline-none"
          />
          <button className="bg-[#56d879] hover:bg-teal-500 duration-300 px-5 py-2.5 rounded-md text-whie md:w-auto w-full">
            Submit
          </button>
        </div>
      </div> */}
        <div
          className="grid grid-cols-1 sm:gird-cols-3 lg:grid-cols-4 gap-6 sm:text-center footer-box"
          style={{ paddingBottom: "30px", paddingTop: "100px" }}
        >
          <ul className="text-center sm:text-start flex sm:block flex-col items-center p-2.5">
            <img
              style={{ height: "29px", paddingLeft: "10px" }}
              src="../lipable-logo-light.png"
              alt="Lipable"
            />
            <br />
            <div
              className="flex items-center"
              style={{ paddingLeft: "10px", gap: "5px" }}
            >
              <Link
                to={"https://www.facebook.com/people/Lipable/61556195275586/"}
              >
                <IoLogoFacebook
                  size={45}
                  className="social-icon"
                  style={{
                    cursor: "pointer",
                    padding: "14.4px",
                    backgroundColor: "#FFFFFF1A",
                    borderRadius: "50%",
                  }}
                />
              </Link>
              <Link to={"https://twitter.com/lipable_ke"}>
                <FaXTwitter
                  size={45}
                  className="social-icon"
                  style={{
                    cursor: "pointer",
                    padding: "14.4px",
                    backgroundColor: "#FFFFFF1A",
                    borderRadius: "50%",
                  }}
                />
              </Link>
              <Link to={"https://www.instagram.com/lipable.ke"}>
                <AiOutlineInstagram
                  size={45}
                  className="social-icon"
                  style={{
                    cursor: "pointer",
                    padding: "14.4px",
                    backgroundColor: "#FFFFFF1A",
                    borderRadius: "50%",
                  }}
                />
              </Link>
            </div>
          </ul>

          <ul className="text-center sm:text-start p-2.5">
            <h1 className="mb-5 font-semibold footer-title">Company</h1>
            {footerProductLinks.map((link, index) => (
              <li key={index}>
                <Link
                  className="duration-300 cursor-pointer footer-item-list"
                  to={link.link}
                >
                  {link.name}
                </Link>
              </li>
            ))}
          </ul>

          <ul className="text-center sm:text-start p-2.5">
            <h1 className="mb-5 font-semibold footer-title">Shop</h1>
            {footercompanyLinks.map((link, index) => (
              <li key={index}>
                <Link
                  className="duration-300 cursor-pointer footer-item-list"
                  to={link.link}
                >
                  {link.name}
                </Link>
              </li>
            ))}
          </ul>

          <ul className="text-center sm:text-start p-2.5">
            <h1 className="mb-5 font-semibold footer-title">Support</h1>
            {footerSupportLinks.map((link, index) => (
              <li key={index}>
                <Link
                  className="duration-300 cursor-pointer footer-item-list"
                  to={link.link}
                >
                  {link.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>

        <div className="sm:text-left footer-box">
          <p
            style={{
              fontFamily: "Inter",
              fontSize: "12px",
              color: "#677471",
              padding: "10px",
              lineHeight: "1.75",
            }}
          >
            Payment options through Lipable are provided by these sacco
            partners:{" "}
            <a
              className="footer-links"
              href="https://www.lipable.com/for-saccos"
              style={{ textDecoration: "underline" }}
            >
              lipable.com/saccos.
            </a>{" "}
            Your rate will be 0–36% APR based on credit, and is subject to an
            eligibility check. Options depend on your purchase amount, may vary
            by merchant, and may not be available countrywide. A down payment
            may be required. Lipable Fintech Solutions Limited, is registered in
            the Republic of Kenya. For terms and conditions, see{" "}
            <a
              className="footer-links"
              href="https://www.lipable.com/terms-conditions"
              style={{ textDecoration: "underline" }}
            >
              lipable.com/terms.
            </a>{" "}
          </p>
        </div>
        <div
          className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10
         text-right pt-9 text-sm pb-11 p-2.5 footer-box"
        >
          <span></span>
          <span></span>
          <span
            style={{ fontFamily: "Inter", color: "#677471", fontSize: "14px" }}
          >
            © 2024 · Lipable™ ·{" "}
            <a className="footer-links" href="https://shop.lipable.com">
              Swipe, Shop, Settle.
            </a>
          </span>
        </div>
      </div>
    </>
  );
};

export default Footer;
