import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styles from "../../styles/styles";
import { productData, categoriesData } from "../../static/data";
import { theme } from "../Theme/Theme";
import {
  AiOutlineHeart,
  AiOutlineSearch,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import {
  IoArrowForward,
  IoSearch,
  IoClose,
  IoStorefrontOutline,
  IoPersonOutline,
  IoCartOutline,
  IoBagHandleOutline,
} from "react-icons/io5";
import { IoIosArrowForward } from "react-icons/io";
import { BiMenuAltLeft } from "react-icons/bi";
import { CgProfile } from "react-icons/cg";
import DropDown from "./DropDown";
import Navbar from "./Navbar";
import { useSelector } from "react-redux";
import { backend_url } from "../../server";
import Cart from "../cart/Cart";
import Wishlist from "../Wishlist/Wishlist";
import { RxCross1 } from "react-icons/rx";
import { Avatar, TextField, ThemeProvider } from "@material-ui/core";
import { formatCurrency } from "../../Utils/formatCurrency";
import Lottie from "react-lottie";
import animationData from "../../Assests/animations/search.json";

const Header = ({ activeHeading }) => {
  const { isSeller } = useSelector((state) => state.seller);
  const { cart } = useSelector((state) => state.cart);
  const { wishlist } = useSelector((state) => state.wishlist);
  const { isAuthenticated, user } = useSelector((state) => state.user);
  const { allProducts } = useSelector((state) => state.products);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [active, setActive] = useState(false);
  const [dropDown, setDropDown] = useState(false);
  const [openCart, setOpenCart] = useState(false);
  const [openWishlist, setOpenWishlist] = useState(false);
  const [open, setOpen] = useState(false); // mobile menu
  const [scrollPosition, setScrollPosition] = useState(0);
  const [visible, setVisible] = useState(true);
  const [isFocused, setIsFocused] = useState(false);
  const [hasText, setHasText] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [bannerOpen, setBannerOpen] = useState(true);
  const [play, setPlay] = useState(true);

  const handleScroll = () => {
    const currentScrollPos = window.scrollY;
    const visible = scrollPosition > currentScrollPos || currentScrollPos < 10;
    setVisible(visible);
    setScrollPosition(currentScrollPos);
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  // Update the search term and hasText state
  const handleInputChange = (e) => {
    handleSearchChange(e); // Call the parent change handler
    setHasText(e.target.value.length > 0); // Update hasText state
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollPosition]);

  // Handle search change
  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    // Filter products
    const filteredProducts =
      allProducts &&
      allProducts.filter((product) =>
        product.name.toLowerCase().includes(term.toLowerCase())
      );
    setSearchData(filteredProducts);
  };

  const clearInput = () => {
    setSearchData([]);
    setSearchTerm("");
  };

  window.addEventListener("scroll", () => {
    if (window.scrollY > 70) {
      setActive(true);
    } else {
      setActive(false);
    }
  });

  const getInitials = (name) => {
    if (!name) return "";
    const nameParts = name.split(" ");
    const initials = nameParts.map((part) => part[0]).join("");
    return initials.toUpperCase();
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        {/* Top Bar */}
        <div className="top-bar">
          <div className="top-bar-container top-bar-animate">
            <ul style={{ display: "flex" }} className="menu-top-bar-menu">
              <li className="top-bar-li">
                <a href="https://lipable.com">For Shoppers</a>
              </li>
              <li className="top-bar-li2 top-bar-list">
                <a href="https://lipable.com/for-merchants">For Merchants</a>
              </li>
            </ul>
          </div>
        </div>

        {/*  2nd part of header start */}
        <div
          className={`${
            active == true ? "shadow-sm fixed top-0 left-0 z-10" : null
          } transition hidden 800px:flex items-center justify-between w-full bg-[#fff] h-[70px]`}
        >
          <div
            className={`${styles.section}`}
            style={{ backgroundColor: "#fff" }}
          >
            <div className="hidden 800px:h-[28px] 800px:my-[20px] 800px:flex items-center justify-between ">
              <div>
                <Link to="/">
                  <img
                    style={{ height: "35px", paddingLeft: "10px" }}
                    src="../Lipable-logo.png"
                    alt="Lipable"
                  />
                </Link>
              </div>
              <div className={`${styles.noramlFlex}`}>
                <Navbar active={activeHeading} />
              </div>
              {/*Search box  */}
              <div className="w-[35%] relative">
                {/* <input
                  type="text"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  placeholder="Search products..."
                  className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                /> */}
                <input
                  type="text"
                  placeholder="Search for any product"
                  value={searchTerm}
                  onChange={handleInputChange}
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => setIsFocused(false)}
                  className="h-[2.5rem] w-full px-2 border-[#a3a2a2] border-[1px]"
                  style={{
                    borderRadius:
                      hasText && isFocused ? "22px 22px 0 0" : "22px",
                    borderBottom:
                      hasText && isFocused
                        ? "1px solid transparent"
                        : "1px solid #a3a2a2",
                    backgroundColor: "#fefefe",
                    fontFamily: "Inter",
                    paddingLeft: "2.5rem",
                  }}
                />
                <IoSearch
                  size={24}
                  className="absolute left-2"
                  style={{ top: "0.48rem", color: "#615f6d" }}
                />
                <div>
                  {searchData.length === 0 ? (
                    <IoArrowForward
                      size={25}
                      className="absolute right-2 cursor-pointer"
                      style={{
                        top: "0.45rem",
                        backgroundColor: "#ec00ff",
                        padding: "4px",
                        borderRadius: "50%",
                        transition: "0.6s",
                      }}
                    />
                  ) : (
                    <IoClose
                      size={25}
                      onClick={clearInput}
                      className="absolute right-2 cursor-pointer"
                      style={{
                        top: "0.45rem",
                        backgroundColor: "#ec00ff",
                        padding: "4px",
                        borderRadius: "50%",
                        transition: "0.6s",
                      }}
                    />
                  )}
                </div>

                {isFocused && hasText && (
                  <div
                    className="absolute min-h-[30vh] bg-white shadow-sm-2 z-[9] pt-4 pb-4 w-full border-l border-r border-b border-[#a3a2a2]"
                    style={{
                      borderBottomRightRadius: "20px",
                      borderBottomLeftRadius: "20px",
                    }}
                  >
                    {searchData.length === 0 ? (
                      <div>
                        <Lottie
                          options={defaultOptions}
                          width={100}
                          height={100}
                        />
                        <div className="text-center text-gray-500 font-Inter">
                          No product found
                        </div>
                      </div>
                    ) : (
                      searchData.map((i, index) => (
                        <Link
                          to={`/product/${i.slug}`}
                          key={index}
                          onMouseDown={(e) => e.preventDefault()}
                        >
                          <div
                            className="w-full flex items-start-py-3 pb-2 justify-between hover:bg-[#f3f3f5]"
                            style={{
                              paddingLeft: "1rem",
                              paddingRight: "1rem",
                              paddingTop: "0.4rem",
                            }}
                          >
                            <div className="flex">
                              <img
                                src={`${backend_url}${i.images[0]}`}
                                alt="img"
                                className="w-[40px] h-[40px] mr-[10px] rounded-sm"
                              />
                              <div>
                                <h1 className="font-Inter">{i.name}</h1>
                                <h4
                                  className="font-Inter font-light"
                                  style={{ fontSize: "0.75rem" }}
                                >
                                  {i.category}
                                </h4>
                              </div>
                            </div>
                            <h1 className="font-Inter font-semibold pt-[10px]">
                              {formatCurrency(
                                i.originalPrice === 0
                                  ? i.originalPrice
                                  : i.discountPrice
                              )}
                            </h1>
                          </div>
                        </Link>
                      ))
                    )}
                  </div>
                )}
              </div>
              {/* Search end */}

              {/* Sign Up and Sign In */}
              {isAuthenticated ? (
                <div className="flex items-center">
                  <div>
                    <Link to="/cart">
                      <div className="relative mr-[20px] cursor-pointer">
                        <IoBagHandleOutline size={24} />
                        <span class="absolute left-3 bottom-4 rounded-full bg-[#ef8ff8] w-4 h-4 top right p-0 m-0 text-black font-Inter text-[12px]  leading-tight text-center">
                          {cart && cart.length}
                        </span>
                      </div>
                    </Link>
                  </div>
                  {/* cart popup */}
                  {/* {openCart ? <Cart setOpenCart={setOpenCart} /> : null} */}

                  <div>
                    <Link to="/wishlist">
                      <div className="relative mr-[20px] cursor-pointer">
                        <AiOutlineHeart size={24} />
                      </div>
                    </Link>
                  </div>

                  {/* wishlist popup */}
                  {/* {openWishlist ? (
                    <Wishlist setOpenWishlist={setOpenWishlist} />
                  ) : null} */}
                  <Link to="/dashboard">
                    <div className="flex items-center bg-[#fbf7ed] py-2 px-3 rounded-[50px]">
                      {user.avatar ? (
                        <img
                          src={`${backend_url}${user.avatar}`}
                          alt="Profile img"
                          className="w-[30px] h-[30px] rounded-full mr-2"
                        />
                      ) : (
                        <Avatar
                          alt={user?.name}
                          style={{
                            width: "30px",
                            height: "30px",
                            marginRight: "10px",
                            borderRadius: "50px",
                            backgroundColor: "#dfdfdf",
                          }}
                        >
                          <span className="font-Inter p-2 text-[#000] text-[0.8rem] font-medium">
                            {getInitials(user.name)}
                          </span>
                        </Avatar>
                      )}
                      <span className="font-Inter">{user.name}</span>
                    </div>
                  </Link>
                </div>
              ) : (
                <div
                  style={{ paddingRight: "10px", display: "flex" }}
                  className="sign-up-section"
                >
                  <div>
                    <Link to="/cart">
                      <div className="relative mr-[20px] cursor-pointer">
                        <IoBagHandleOutline size={24} />
                        <span class="absolute left-3 bottom-4 rounded-full bg-[#ef8ff8] w-4 h-4 top right p-0 m-0 text-black font-Inter text-[12px]  leading-tight text-center">
                          {cart && cart.length}
                        </span>
                      </div>
                    </Link>
                  </div>

                  <div class=" flex items-center justify-center">
                    <div class="group relative cursor-pointer py-2">
                      <div class="flex items-center justify-between bg-white">
                        <a
                          class="my-2 py-2 text-base text-black ml-2 font-Inter link-button"
                          onClick=""
                        >
                          Sign In
                        </a>
                        <span className="ml-[10px]">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="h-4 w-4"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                            />
                          </svg>
                        </span>
                      </div>

                      <div class="invisible absolute z-50 flex w-[250px] flex-col bg-white py-1 px-4 text-gray-800 shadow-gray-200 shadow-2xl group-hover:visible">
                        <a class="my-2  border-gray-100 py-1 font-normal text-gray-500 hover:text-black md:mx-2 font-Inter">
                          <Link to="/login">
                            {" "}
                            <div className="flex items-center">
                              <IoPersonOutline />{" "}
                              <span className="pl-2">Shopper sign in</span>{" "}
                            </div>
                          </Link>
                        </a>

                        <a class="my-2 border-gray-100 py-1 font-normal text-gray-500 hover:text-black md:mx-2 font-Inter">
                          <Link to="/merchant/login">
                            <div className="flex items-center">
                              <IoStorefrontOutline />{" "}
                              <span className="pl-2">Merchant sign in</span>
                            </div>
                          </Link>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="primary-button cursor-pointer">
                    <Link to="/sign-up">
                      <h1 className="text-[#fff] flex items-center">
                        {"Sign Up"}{" "}
                      </h1>
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Mobile Header */}
        <div
          className={`${
            active === true ? "shadow-sm fixed top-0 left-0 z-10" : null
          }
            w-full h-[60px] bg-[#fff] z-50 top-0 left-0 shadow-sm 800px:hidden`}
        >
          <div className="w-full flex items-center justify-between">
            <div>
              <BiMenuAltLeft
                size={40}
                className="ml-4"
                onClick={() => setOpen(true)}
              />
            </div>
            <div>
              <Link to="/">
                <img
                  style={{ height: "35px", paddingLeft: "10px" }}
                  src="../Lipable-logo.png"
                  alt="Lipable"
                />
              </Link>
            </div>

            <div>
              <div
                className="relative mr-[20px]"
                onClick={() => setOpenCart(true)}
              >
                <AiOutlineShoppingCart size={30} />
                <span class="absolute right-0 top-0 rounded-full bg-[#3bc177] w-4 h-4 top right p-0 m-0 text-white font-mono text-[12px]  leading-tight text-center">
                  {cart && cart.length}
                </span>
              </div>
            </div>
            {/* cart popup */}
            {openCart ? <Cart setOpenCart={setOpenCart} /> : null}

            {/* wishlist popup */}
            {openWishlist ? (
              <Wishlist setOpenWishlist={setOpenWishlist} />
            ) : null}
          </div>
        </div>

        {/*  side bar*/}
        {open ? (
          <div
            className={`fixed w-full bg-[#0000005f] z-20 h-full top-0 left-0`}
          >
            <div className="fixed w-[70%] bg-[#fff] h-screen top-0 left-0 z-10 overflow-y-scroll">
              <div className="w-full justify-between flex pr-3">
                <div>
                  <div
                    className="relative mr-[15px]"
                    onClick={() => setOpenWishlist(true) || setOpen(false)}
                  >
                    <AiOutlineHeart size={30} className="mt-5 ml-3" />
                    <span class="absolute right-0 top-0 rounded-full bg-[#3bc177] w-4 h-4 top right p-0 m-0 text-white font-mono text-[12px]  leading-tight text-center">
                      {wishlist && wishlist.length}
                    </span>
                  </div>
                </div>

                <RxCross1
                  size={30}
                  className="ml-4 mt-5 cursor-pointer"
                  onClick={() => setOpen(false)}
                />
              </div>

              {/* Search Bar */}
              <div className="my-8 w-[92%] m-auto h-[40px relative]">
                <input
                  type="search"
                  placeholder="Search for products"
                  className="h-[40px] w-full px-2 border-[#3957db] border-[2px] rounded-md"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />

                {searchData && (
                  <div className="absolute bg-[#fff] z-10 shadow w-full left-0 p-3">
                    {searchData.map((i) => {
                      const d = i.name;

                      const Product_name = d.replace(/\s+/g, "-");
                      return (
                        <Link to={`/product/${Product_name}`}>
                          <div className="flex items-center">
                            <img
                              src={i.image_Url[0].url}
                              alt=""
                              className="w-[50px] mr-2"
                            />
                            <h5>{i.name}</h5>
                          </div>
                        </Link>
                      );
                    })}
                  </div>
                )}
              </div>
              <Navbar active={activeHeading} />
              <div className={`${styles.button} ml-4 !rounded-[4px]`}>
                <Link to={`${isSeller ? "/dashboard" : "/shop-create"}`}>
                  <h1 className="text-[#fff] flex items-center">
                    {isSeller ? "Go Dashboard" : "Become Seller"}{" "}
                    <IoIosArrowForward className="ml-1" />
                  </h1>
                </Link>
              </div>
              <br />
              <br />
              <br />

              {/* Mob Login */}
              <div className="flex w-full justify-center">
                {isAuthenticated ? (
                  <div>
                    <Link to="/profile">
                      <img
                        src={`${backend_url}${user.avatar}`}
                        alt="Profile img"
                        className="w-[60px] h-[60px] rounded-full border-[3px] border-[#0eae88]"
                      />
                    </Link>
                  </div>
                ) : (
                  <>
                    <Link
                      to="/login"
                      className="text-[18px] pr-[10px] text-[#000000b7]"
                    >
                      Login{" "}
                    </Link>
                    <Link
                      to="/sign-up"
                      className="text-[18px] text-[#000000b7]"
                    >
                      Sign up{" "}
                    </Link>
                  </>
                )}
              </div>
            </div>
          </div>
        ) : null}
      </ThemeProvider>
    </>
  );
};

export default Header;
