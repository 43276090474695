import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import Loader from "../components/Layout/Loader";
import ProductCard from "../components/Route/ProductCard/ProductCard";
import styles from "../styles/styles";
import Lottie from "react-lottie";
import animationData from "../Assests/animations/no-product.json";
import { IoOptionsOutline } from "react-icons/io5";
import { FaArrowDownShortWide } from "react-icons/fa6";
import { categoriesData } from "../static/data";

const ProductsPage = () => {
  const [searchParams] = useSearchParams();
  const categoryData = searchParams.get("category");
  const { allProducts, isLoading } = useSelector((state) => state.products);
  const [filteredData, setFilteredData] = useState([]);
  const [filter, setFilter] = useState("");
  const [isFilterOpen, setIsFilterOpen] = useState(false); // State for pop-up visibility
  const [isPriceOpen, setIsPriceOpen] = useState(false); // State for pop-up visibility
  const navigate = useNavigate();
  const popupRef = useRef(null);
  const [isLoader, setIsLoader] = useState(false); // Loader state
  const [currentPage, setCurrentPage] = useState(1); // Pagination state
  const productsPerPage = 20; // Number of products per page
  const [categories, setCategories] = useState([]);
  const [data, setData] = useState([]);
  // const [priceRange, setPriceRange] = useState([0, 0]);
  // const [selectedPriceRange, setSelectedPriceRange] = useState([0, 0]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    // Ensure allProducts is an array
    const productsList = allProducts || [];

    // Extract unique categories from products
    if (productsList.length > 0) {
      const uniqueCategories = [
        ...new Set(productsList.map((product) => product.category)),
      ];
      setCategories(uniqueCategories);
    }

    let products = [...productsList];

    // Apply sorting based on the filter
    if (filter === "lowToHigh") {
      products.sort((a, b) => a.discountPrice - b.discountPrice);
    } else if (filter === "recentlyAdded") {
      products.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    }

    // Apply category filtering if categoryData is set
    if (categoryData) {
      products = products.filter((product) =>
        product.category?.toLowerCase().includes(categoryData.toLowerCase())
      );
    }

    // Update state with filtered and sorted products
    setFilteredData(products);
    setCurrentPage(1); // Reset to the first page on new filter

    setIsLoader(true);
    setTimeout(() => {
      setFilteredData(products);
      setIsLoader(false); // Stop the loader after the operation
    }, 1000); // Adjust the timeout as needed
  }, [allProducts, categoryData, filter]);

  // useEffect(() => {
  //   if (allProducts.length > 0) {
  //     const prices = allProducts.map((product) => product.discountPrice);
  //     const minPrice = Math.min(...prices);
  //     const maxPrice = Math.max(...prices);

  //     setPriceRange([minPrice, maxPrice]);
  //     setSelectedPriceRange([minPrice, maxPrice]);
  //   }
  // }, [allProducts]);

  // const handlePriceChange = (min, max) => {
  //   setSelectedPriceRange([min, max]);
  // };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
    setIsFilterOpen(false); // Close the pop-up after selection
    setIsPriceOpen(false);
  };

  const closePopup = () => {
    setIsFilterOpen(false);
    setIsPriceOpen(false);
  };

  // Close the popup when clicking outside
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        closePopup();
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleNavigate = () => {
    navigate("/products");
    window.location.reload();
  };

  const handleCategoryClick = (category) => {
    navigate(`/products?category=${category}`);
  };

  // Pagination logic
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredData.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const totalPages = Math.ceil(filteredData.length / productsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to the top of the page
  };

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: { preserveAspectRatio: "xMidYMid slice" },
  };

  return (
    <>
      <div>
        <Header activeHeading={2} />
        <div className="bg-[#f2f0ea] h-[15rem] w-full flex items-center justify-center">
          <div>
            <h1 className="text-4xl font-bold font-SplineSans">
              {categoryData
                ? categoriesData.find((cat) =>
                    cat.title.toLowerCase().includes(categoryData.toLowerCase())
                  )?.title || "Category"
                : "All Products"}
            </h1>
          </div>
        </div>
        <div className={`${styles.section} mb-40`}>
          {/* Category Chips */}

          {!categoryData && categories.length > 0 && (
            <>
              <h3 className="text-lg font-semibold mb-4 font-Inter mt-6">
                Categories
              </h3>
              <div className="flex flex-wrap gap-2 mb-6">
                {categories.map((category) => (
                  <button
                    key={category}
                    className="bg-gray-100 text-black px-6 py-2 rounded-xl text-[16px] font-medium border-2 font-Inter border-gray-300 hover:bg-[#000] hover:text-white hover:border-none"
                    onClick={() => handleCategoryClick(category)}
                  >
                    {category}
                  </button>
                ))}
              </div>
            </>
          )}
          {/* Pop-Up Filter */}
          <div className="relative mb-6 mt-4 flex justify-end">
            {/* <button
              className="bg-white text-black px-4 py-2 rounded-lg flex items-center border-2 mr-2 font-Inter"
              onClick={() => setIsPriceOpen(!isPriceOpen)}
            >
              <FaArrowDownShortWide /> <span className="ml-1">Price Range</span>
            </button> */}
            <button
              className="bg-white text-black px-4 py-2 rounded-lg flex items-center border-2 font-Inter"
              onClick={() => setIsFilterOpen(!isFilterOpen)}
            >
              <IoOptionsOutline /> <span className="ml-1">Filters</span>
            </button>

            {isFilterOpen && (
              <div
                className="absolute top-12 bg-white border border-gray-300 rounded-lg shadow-lg p-4 w-80 z-50 right-0"
                ref={popupRef}
              >
                <h3 className="text-lg font-medium mb-4 font-Inter">Filter</h3>

                <h4 className="text-sm font-normal mb-1 font-Inter">
                  Price from cheap to expensive
                </h4>
                <select
                  className="w-full border text-sm border-gray-300 p-2 rounded-lg focus:outline-none focus:ring focus:ring-[#bf00ff] mb-4"
                  value={filter}
                  onChange={handleFilterChange}
                >
                  <option className="text-sm" value="">
                    Select
                  </option>
                  <option className="text-sm" value="lowToHigh">
                    Price: Low to High
                  </option>
                </select>
                <h4 className="text-sm font-normal mb-1 font-Inter">
                  Latest product
                </h4>
                <select
                  className="w-full border text-sm border-gray-300 p-2 rounded-lg focus:outline-none focus:ring focus:ring-[#bf00ff] mb-2"
                  value={filter}
                  onChange={handleFilterChange}
                >
                  <option className="text-sm" value="">
                    Select
                  </option>
                  <option className="text-sm" value="recentlyAdded">
                    Recently Added
                  </option>
                </select>
                <button
                  className="mt-4 w-full bg-white text-black px-4 py-2 rounded-lg border-2"
                  onClick={() => {
                    setFilter(""); // Clear filter
                    setFilteredData(allProducts); // Reset to all products
                  }}
                >
                  Reset all
                </button>
              </div>
            )}
            {/* {isPriceOpen && (
              <div
                className="absolute top-12 bg-white border border-gray-300 rounded-lg shadow-lg p-4 w-80 z-50 right-0"
                ref={popupRef}
              >
                <h3 className="text-lg font-medium mb-4 font-Inter">Filter</h3>

                <div className="mt-4">
                  <h4 className="font-medium">Price Range</h4>
                  <div className="flex justify-between items-center mt-2">
                    <span>{priceRange[0]}</span>
                    <input
                      type="range"
                      min={priceRange[0]}
                      max={priceRange[1]}
                      value={selectedPriceRange[0]}
                      onChange={(e) =>
                        handlePriceChange(
                          Number(e.target.value),
                          selectedPriceRange[1]
                        )
                      }
                      className="w-1/3"
                    />
                    <span>{priceRange[1]}</span>
                  </div>
                  <div className="flex justify-between mt-2">
                    <span>{selectedPriceRange[0]}</span>
                  </div>
                </div>
                <button
                  className="mt-4 w-full bg-white text-black px-4 py-2 rounded-lg border-2"
                  onClick={() => {
                    setFilter(""); // Clear filter
                    setFilteredData(allProducts); // Reset to all products
                  }}
                >
                  Reset all
                </button>
              </div>
            )} */}
          </div>

          {/* Loader or Product Grid */}
          {isLoader ? (
            <div className="flex justify-center items-center h-[500px]">
              <Loader /> {/* Show loader while the section is loading */}
            </div>
          ) : (
            <>
              <div className="grid grid-cols-1 gap-y-[250px] gap-x-[20px] md:grid-cols-2 md:gap-x-[25px] lg:grid-cols-4 lg:gap-x-[25px] xl:grid-cols-5 xl:gap-x-[30px] mb-12">
                {currentProducts.length > 0 ? (
                  currentProducts.map((product) => (
                    <ProductCard
                      key={product.slug}
                      data={product}
                      isEvent={false}
                    />
                  ))
                ) : (
                  <div></div>
                )}
              </div>
              {filteredData && filteredData.length === 0 ? (
                <div className="flex flex-col items-center">
                  <Lottie options={defaultOptions} width={200} height={200} />
                  <h1 className="text-center w-full text-[20px] font-Inter font-medium">
                    No products Found!
                  </h1>
                  <h5 className="text-center w-full pb-[10px] text-[14px] font-Inter font-light text-gray-500">
                    We are working hard to add them in the mean time <br />
                    you can checkout our other categories
                  </h5>
                  <div className="ml-14 mr-14 mb-10">
                    <button
                      onClick={handleNavigate}
                      className="group relative w-[250px] h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#000] hover:bg-[#4d4c4c] font-Inter"
                    >
                      Continue shopping
                    </button>
                  </div>
                </div>
              ) : null}

              {/* Pagination */}
              {totalPages > 1 && (
                <div className="flex justify-center pt-52">
                  {Array.from({ length: totalPages }, (_, i) => (
                    <button
                      key={i + 1}
                      className={`mx-1 px-4 py-2 border ${
                        i + 1 === currentPage
                          ? "bg-black text-white"
                          : "bg-white text-black"
                      } rounded-lg`}
                      onClick={() => handlePageChange(i + 1)}
                    >
                      {i + 1}
                    </button>
                  ))}
                </div>
              )}
            </>
          )}
        </div>
        <Footer />
      </div>
    </>
  );
};

export default ProductsPage;
