import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "../../styles/styles";
import {
  AiFillHeart,
  AiOutlineHeart,
  AiOutlineMessage,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { IoBagHandleOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { getAllProductsShop } from "../../redux/actions/product";
import { backend_url, server } from "../../server";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../redux/actions/wishlist";
import { addTocart } from "../../redux/actions/cart";
import { toast } from "react-toastify";
import Ratings from "./Ratings";
import axios from "axios";
import { formatCurrency } from "../../Utils/formatCurrency";
import { Image, Shimmer } from "react-shimmer";
import Loader from "../Layout/Loader";

const ProductDetails = ({ data }) => {
  const { products } = useSelector((state) => state.products);
  const { user, isAuthenticated } = useSelector((state) => state.user);
  const { wishlist } = useSelector((state) => state.wishlist);
  const { cart } = useSelector((state) => state.cart);
  const dispatch = useDispatch();

  const [count, setCount] = useState(1);
  const [click, setClick] = useState(false);
  const [select, setSelect] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getAllProductsShop(data && data?.shop._id));
    if (wishlist && wishlist.find((i) => i._id === data?._id)) {
      setClick(true);
    } else {
      setClick(false);
    }
  }, [data, wishlist]);

  // Remove from wish list
  const removeFromWishlistHandler = (data) => {
    setClick(!click);
    dispatch(removeFromWishlist(data));
  };

  // add to wish list
  const addToWishlistHandler = (data) => {
    setClick(!click);
    dispatch(addToWishlist(data));
  };

  // Add to cart
  const addToCartHandler = (id) => {
    const isItemExists = cart && cart.find((i) => i._id === id);

    if (isItemExists) {
      toast.error("Product already in cart!");
    } else {
      if (data.stock < 1) {
        toast.error("Limited stock!");
      } else {
        const cartData = { ...data, qty: count };
        dispatch(addTocart(cartData));
        toast.success("Item added to cart!");
      }
    }
  };

  const incrementCount = () => {
    setCount(count + 1);
  };
  const decrementCount = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };

  const totalReviewsLength =
    products &&
    products.reduce((acc, product) => acc + product.reviews.length, 0);

  const totalRatings =
    products &&
    products.reduce(
      (acc, product) =>
        acc + product.reviews.reduce((sum, review) => sum + review.rating, 0),
      0
    );

  const avg = totalRatings / totalReviewsLength || 0;

  const averageRating = avg.toFixed(1);

  const [isLoading, setIsLoading] = useState(true); // State to control the loader

  useEffect(() => {
    // Simulate a network request or some async operation
    setTimeout(() => {
      setIsLoading(false); // Set loading to false after a delay
    }, 500); // Adjust the timeout duration as needed
  }, []);

  if (isLoading) {
    return <Loader />; // Show loader while loading
  }

  return (
    <div className="bg-white">
      {data ? (
        <div className="">
          <div className="w-11/12 mx-auto 800px:w-80%">
            <div className="w-[100%] py-12">
              <div className="block w-full 800px:flex">
                <div className="w-full 800px:w-[50%] flex">
                  <div className="block mr-1">
                    {data &&
                      data.images.map((i, index) => (
                        <div key={index} className="cursor-pointer">
                          <img
                            src={`${backend_url}${i}`}
                            alt=""
                            className={`h-[86px] w-[86px] overflow-hidden mr-3 rounded-xl object-cover mb-1 bg-[#f2f0ea] ${
                              select === index
                                ? "border border-black"
                                : "border border-[#cfcecb]"
                            }`}
                            onClick={() => setSelect(index)}
                          />
                        </div>
                      ))}
                  </div>
                  <img
                    src={`${backend_url}${data && data.images[select]}`}
                    alt=""
                    className="rounded-2xl object-cover h-[400px] w-[400px] bg-[#f2f0ea]"
                  />
                </div>
                {/* Right */}
                <div className="w-full 800px:w-[50%] pt-5 ">
                  <h1
                    className={"text-[2rem] font-[700] font-Inter text-[#000]"}
                  >
                    {data.name}
                  </h1>
                  <h5
                    className={
                      "text-[1rem] font-[400] font-Inter text-[#777] pt-2"
                    }
                  >
                    {data.category}
                  </h5>
                  <p className="font-Inter text-[1rem] pt-3 text-[#2c2c2c]">
                    {data.shortDescription}
                  </p>
                  {/* <div className="flex pt-5 items-center">
                    <h4
                      className={
                        "font-bold text-[20px] text-[#ff5b3e] font-Inter"
                      }
                    >
                      {formatCurrency(data.discountPrice)}
                    </h4>
                    <h3
                      className={
                        "font-[500] text-[20px] text-[#696969] pl-3 line-through font-Inter"
                      }
                    >
                      {formatCurrency(
                        data.originalPrice ? data.originalPrice : null
                      )}
                    </h3>
                  </div> */}
                  <div className="flex pt-5 items-center">
                    {data.originalPrice ? (
                      <>
                        {/* Show discountPrice in orange if originalPrice is available */}
                        <h4
                          className={
                            "font-bold text-[20px] text-[#ff5b3e] font-Inter"
                          }
                        >
                          {formatCurrency(data.discountPrice)}
                        </h4>
                        {/* Show originalPrice in grey with a line-through */}
                        <h3
                          className={
                            "font-[500] text-[20px] text-[#696969] pl-3 line-through font-Inter"
                          }
                        >
                          {formatCurrency(data.originalPrice)}
                        </h3>
                      </>
                    ) : (
                      <>
                        {/* Show only discountPrice in black if originalPrice is not available */}
                        <h4
                          className={
                            "font-bold text-[24px] text-[#333] font-Inter"
                          }
                        >
                          {formatCurrency(data.discountPrice)}
                        </h4>
                      </>
                    )}
                  </div>
                  <h5
                    className={`text-[1rem] font-[400] font-Inter pt-2 ${
                      data.stock > 0 ? "text-green-500" : "text-red-500"
                    }`}
                  >
                    {data.stock > 0
                      ? `${data.stock} items in stock`
                      : "Out of stock"}
                  </h5>
                  {/* inc dec option */}
                  <div className="flex items-center mt-12 justify-between pr-3">
                    <div>
                      <button
                        className=" text-gray-500 font-bold px-4 py-2 hover:opacity-75 transition duration-300 ease-in-out rounded-md"
                        onClick={decrementCount}
                        style={{ border: "2px solid #eee" }}
                      >
                        -
                      </button>

                      <span className="bg-white text-gray-800 font-medium px-4 py-[11px] font-Inter">
                        {count}
                      </span>

                      <button
                        className="text-gray-500 font-bold px-4 py-2 hover:opacity-75 transition duration-300 ease-in-out rounded-md"
                        onClick={incrementCount}
                        style={{ border: "2px solid #eee" }}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div className="flex items-center">
                    {/* <div
                    className={`${styles.button} !mt-6 !h-11 flex items-center rounded-md text-white bg-[#BF00FF] hover:bg-[#000] font-Inter`}
                    onClick={() => addToCartHandler(data._id)}
                  >
                    <span className="text-white flex items-center">
                      <IoBagHandleOutline className="mr-2" /> Add to Cart
                    </span>
                  </div> */}
                    <button
                      onClick={() =>
                        data.stock > 0 && addToCartHandler(data._id)
                      }
                      disabled={data.stock === 0}
                      className={`group !mt-6 relative w-[250px] h-[45px] flex justify-center py-[9px] px-4 border border-transparent text-[14px] font-medium rounded-md font-Inter ${
                        data.stock > 0
                          ? "text-white bg-[#000] hover:bg-[#4d4c4c]"
                          : "text-gray-500 bg-gray-300 cursor-not-allowed"
                      }`}
                    >
                      <span className="flex items-center">
                        {data.stock > 0 ? (
                          <>
                            <IoBagHandleOutline className="mr-2" /> Add to Cart
                          </>
                        ) : (
                          "Out of Stock"
                        )}
                      </span>
                    </button>

                    <div className="ml-2 rounded-lg bg-[#f2f0ea] p-3 mt-6">
                      {click ? (
                        <AiFillHeart
                          size={24}
                          className="cursor-pointer"
                          onClick={() => removeFromWishlistHandler(data)}
                          color={click ? "#ff5b3e" : "#333"}
                          title="Remove from wishlist"
                        />
                      ) : (
                        <AiOutlineHeart
                          size={24}
                          className="cursor-pointer"
                          onClick={() => addToWishlistHandler(data)}
                          title="Add to wishlist"
                        />
                      )}
                    </div>
                  </div>

                  <div className="flex items-center pt-8">
                    <div
                      className="flex p-1 shop-button"
                      style={{
                        border: "1px solid #c4c3ca",
                        borderRadius: "30px",
                      }}
                    >
                      <Link to={`#`}>
                        <img
                          src={`${backend_url}${data?.shop?.avatar}`}
                          alt=""
                          className="w-[50px] h-[50px] rounded-full mr-2"
                        />
                      </Link>
                      <div className="pr-8">
                        <Link to={`#`}>
                          <h3
                            className={`pt-1 text-[0.875rem] text-[#0B051D] pb-1 cursor-pointer font-Inter`}
                          >
                            Shop at <br />{" "}
                            <span className="font-semibold">
                              {data.shop.name}
                            </span>
                          </h3>
                        </Link>
                        {/* <h5 className="pb-3 text-[15px] font-Inter">
                      {" "}
                      ({averageRating}/5) Ratings
                    </h5> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Product Details  info */}

          <ProductDetailsInfo
            data={data}
            products={products}
            totalReviewsLength={totalReviewsLength}
            averageRating={averageRating}
          />
          <br />
          <br />
        </div>
      ) : null}
    </div>
  );
};

const ProductDetailsInfo = ({
  data,
  products,
  totalReviewsLength,
  averageRating,
}) => {
  const [active, setActive] = useState(1);

  return (
    <div className="bg-[#fff] w-full py-2 rounded">
      <div className="w-11/12 mx-auto 800px:w-80%">
        <div className="w-full flex justify-start pt-10 pb-2">
          <div className="relative mr-4">
            <h5
              className={`${
                active === 1
                  ? "text-[#000] font-[500]"
                  : "text-gray-400 font-[300]"
              } text-[16px] px-1 pb-4 leading-5  cursor-pointer 800px:text-[16px] font-Inter w-[125px] text-center`}
              onClick={() => setActive(1)}
            >
              Product details
            </h5>
            {active === 1 ? (
              <div
                className={`absolute bottom-[-27%] left-0 h-[2px] w-full bg-[#000]`}
              />
            ) : null}
          </div>

          <div className="relative">
            <h5
              className={`${
                active === 2
                  ? "text-[#000] font-[500]"
                  : "text-gray-400 font-[300]"
              } text-[16px] px-1 pb-4 leading-5  cursor-pointer 800px:text-[16px] font-Inter w-[125px] text-center`}
              onClick={() => setActive(2)}
            >
              Features
            </h5>
            {active === 2 ? (
              <div
                className={`absolute bottom-[-27%] left-0 h-[2px] w-full bg-[#000]`}
              />
            ) : null}
          </div>

          <div className="relative">
            <h5
              className={`${
                active === 3
                  ? "text-[#000] font-[500]"
                  : "text-gray-400 font-[300]"
              } text-[16px] px-1 pb-4 leading-5  cursor-pointer 800px:text-[16px] font-Inter w-[125px] text-center`}
              onClick={() => setActive(3)}
            >
              Reviews
            </h5>
            {active === 3 ? (
              <div
                className={`absolute bottom-[-27%] left-0 h-[2px] w-full bg-[#000]`}
              />
            ) : null}
          </div>
        </div>
      </div>

      {active === 1 ? (
        <div className="w-full bg-[#f2f0ea] ">
          <div className="w-11/12 mx-auto 800px:w-80%">
            <div className="py-6">
              {/* <p className="py-7 px-6 text-[16px] leading-8 pb-10 whitespace-pre-line bg-[#fff] font-Inter rounded-xl drop-shadow-xl">
                {data.description}
              </p> */}
              <div className="py-7 px-6 text-[16px] leading-8 pb-10 bg-[#fff] font-Inter rounded-xl drop-shadow-xl">
                <ul className="list-disc pl-6">
                  {data.description.split("\n").map((line, index) => (
                    <li key={index} className="mb-2">
                      {line.trim()}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {active === 2 ? (
        <div className="w-full bg-[#f2f0ea] ">
          <div className="w-11/12 mx-auto 800px:w-80%">
            <div className="py-6">
              {/* <p className="py-7 px-6 text-[16px] leading-8 pb-10 whitespace-pre-line bg-[#fff] font-Inter rounded-xl drop-shadow-xl">
                {data.features}
              </p> */}
              <div className="py-7 px-6 text-[16px] leading-8 pb-10 whitespace-pre-line bg-[#fff] font-Inter rounded-xl drop-shadow-xl">
                <tbody>
                  {data.features.split("\n").map((feature, index) => (
                    <tr key={index}>
                      <td className="border-b py-2 px-4">{feature}</td>
                    </tr>
                  ))}
                </tbody>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {/* Product Rev */}
      {active === 3 ? (
        <div className="w-full min-h-[40vh] flex flex-col items-center overflow-y-scroll">
          {data &&
            data.reviews.map((item, index) => (
              <div className="w-full flex my-2">
                <img
                  src={`${backend_url}/${item.user.avatar}`}
                  alt=""
                  className="w-[50px] h-[50px] rounded-full"
                />
                <div className="pl-2 ">
                  <div className="w-full flex items-center">
                    <h1 className="font-[500] mr-3 font-Inter">
                      {item.user.name}
                    </h1>
                    <Ratings rating={data?.ratings} />
                  </div>
                  <p className="font-Inter">{item.comment}</p>
                </div>
              </div>
            ))}

          <div className="w-full bg-[#f2f0ea] ">
            <div className="w-11/12 mx-auto 800px:w-80%">
              {data && data.reviews.length === 0 && (
                <div className="py-10">
                  <div className="flex flex-col items-center">
                    <img src="../empty-cart.svg" className="h-[200px]" />
                    <h5 className="font-Inter font-medium mt-2">
                      This product has no reviews
                    </h5>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ProductDetails;
